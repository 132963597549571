import React from 'react'
import theme from 'styles/theme'

import FormButton from '../FormButton'
import Icon from '../Icon'
import Input from '../forms/Input'
import Label from '../forms/Label'

export default function AccountInviteForm() {
  return (
    <div
      css={{
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '36rem',
        width: '100%',
        minHeight: '80vh',
        padding: '5rem 0',
      }}
    >
      <div
        css={{
          width: '100%',
          backgroundColor: '#fff',
          borderRadius: '0.5rem',
          height: 'auto',
          padding: '3rem',
        }}
      >
        <h3 css={{ fontSize: '3rem', textAlign: 'center' }}>Request account invite</h3>
        <p css={{ textAlign: 'center' }}>
          Provide your email below in order to complete your account setup. Once complete, you can
          manage your subscriptions directly within your account.
        </p>
        <form onSubmit={() => {}}>
          <div css={{ margin: '2rem 0' }}>
            <Label for='email'>Email</Label>
            <Input name='email' placeholder='Enter the email you wanna inivite to' />
          </div>
          <FormButton type='submit' icon={<Icon.ArrowForward width={16} height={16} />}>
            Get subcription login
          </FormButton>
          <div css={{ textAlign: 'center', marginTop: '3rem' }}>
            <a href='/login' css={{ textDecoration: 'underline', fontWeight: 'bold' }}>
              Return to login
            </a>
          </div>
        </form>
      </div>
    </div>
  )
}
