import Container from 'components/Container'
import AccountInviteForm from 'components/auth/AccountInviteForm'
import React from 'react'
import { Helmet } from 'react-helmet'

export default function AccountInvitePage() {
  return (
    <Container css={{ position: 'relative' }}>
      <Helmet title='Invite'>
        <meta name='robots' content='noindex' />
      </Helmet>
      <AccountInviteForm />
    </Container>
  )
}
